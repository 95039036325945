<template lang="html">
  <div class="" style="text-align: left;">
    <login></login>
    <!-- Page Contents -->
    <div class="ui menu show" style="border:0;margin:0;box-shadow:0 0 0 0;margin-left:auto;margin-right:auto;">
      <a href="/" @click="showHome" class="item" style="padding-top:0;padding-bottom:0;cursor:pointer;">
        <img class="ui image" src="../assets/images/Logo_Spext_1.png">
      </a>
      <div @click="menu=!menu" class="ui right item" style="padding-top:0;padding-bottom:0;">
        <i class="fa fa-bars"/>
      </div>
    </div>
    <div class="show1" v-show="menu" style="text-align:center;">
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="https://intercom.help/spext/faqs" target="_blank" rel="noopener" @click="showFAQ">FAQs</a>
      </div>
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="/security" @click="showSecurity">SECURITY</a>
      </div>
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="/privacy" @click="showPrivacy">PRIVACY</a>
      </div>
      <div class="item" style="padding-top:15px;">
        <a class="menuItem"  href="https://guides.spext.co/" target="_blank" rel="noopener" @click="showGuide">GUIDES</a>
      </div>
      <!-- <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="https://legal.spext.co/" target="_blank" rel="noopener" @click="showLegal">FOR LEGAL</a>
      </div> -->
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="https://blog.spext.co/" target="_blank" rel="noopener" @click="showBlog">BLOG</a>
      </div>
    </div>
    <div class="ui stackable menu bottomMargin menuHeight hide" style="width:95%;border:0;box-shadow:0 0 0 0;margin-left:auto;margin-right:auto;">
      <a href="/" @click="showHome" class="ui item" style="padding-top:15px;cursor:pointer;">
        <img class="ui image" src="../assets/images/Logo_Spext_1.png">
      </a>
      <div class="ui stackable right menu" style="border:0;box-shadow:0 0 0 0">
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://intercom.help/spext/faqs" target="_blank" rel="noopener" @click="showFAQ">FAQs</a>
        </div>
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="/security" @click="showSecurity">SECURITY</a>
        </div>
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="/privacy" @click="showPrivacy">PRIVACY</a>
        </div>
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://guides.spext.co/" target="_blank" rel="noopener" @click="showGuide">GUIDES</a>
        </div>
        <!-- <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://legal.spext.co/" target="_blank" rel="noopener" @click="showLegal">FOR LEGAL</a>
        </div> -->
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://blog.spext.co/" target="_blank" rel="noopener" @click="showBlog">BLOG</a>
        </div>
      </div>
    </div>

    <div class="container grid ui stackable" style="margin-top:auto;padding-top:2rem;">
      <div class="sixteen wide middle aligned column" style="margin-top:2%;">
        <div class="ui row">
          <div>
            <h1 class="ui p1 header" style="text-align:center;margin-bottom:10px;">
               PRIVACY POLICY
            </h1>
          </div>
          <div style="text-align:center;">
            <div style="font-weight:normal;color:#515151;font-size:14px;">Date of Last Revision: August 10th, 2021</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui column">
      <img class="ui centered image" src="../assets/images/privacy.png">
    </div>

    <div class="container grid ui stackable" style="margin-top:auto;padding-top:2rem;">
      <div class="fourteen wide middle aligned centered column" style="margin-top:2%;">
        <div class="ui row">
          <p>This Privacy Policy sets forth Spext’s policy with respect to information including personally identifiable data (“Personal Data”) and other information that is collected from visitors to the Site and Services.</p>
          <h5>Identity and contact information of the Data Controller:</h5>
          <p>We are the data controller and this means that we determine the purposes for, and ways in which, we collect and use personal information. Spext Inc. is incorporated in Delaware, USA and has offices in California and India.</p>
          <h5>Information We Collect:</h5>
          <p>When you interact with us through the Services, we may collect Personal Data and other information from you, as further described below:</p>
          <h5>Personal Data That You Provide Through the Services:</h5>
          <p>We collect Personal Data from you only when you voluntarily provide such information, such as when you sign up, contact us with support or use certain services. Wherever Spext collects Personal Data we make an effort to provide a link to this Privacy Policy.</p>
          <p>In addition, we may have access to your Personal Data if such data is included in any audio files you send us for transcription services. We only use such Personal Data solely to provide the automatic transcription services to you and to improve our algorithms. Without your authorization, we never sell, lease, or otherwise provide access to such Personal Data to our employees, contractors, agents or other third parties. At no point during the upload and transcription process, do employees, or third parties have access or authorization to view the files.</p>
          <p>By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. You acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers of Spext and the authorized third parties referred to herein located in the United States.</p>
          <h5>Other Information:</h5>
          <p>Non-Identifiable Data: When you interact with spext through the Services, we receive and store certain personally non-identifiable information. Such information, which is collected passively using various technologies, cannot presently be used to specifically identify you. Spext may store such information itself or such information may be included in databases owned and maintained by Spext affiliates, agents or service providers. It is important to note that no Personal Data is available or used in this process.</p>
          <p>In operating the Services, we may use a technology called "cookies." A cookie is a piece of information that helps provide additional functionality to the Services and help us analyze Services usage more accurately. For example, our Site may set a cookie on your browser that allows you to access the Services without needing to remember and then enter a password more than once during a visit to the Site. We recommend that you leave cookies turned on because they allow you to take advantage of some of the Service features.</p>
          <p>In all cases in which we use cookies, we will not collect Personal Data except with your permission. On most web browsers, you will find a “help” section on the toolbar. Please refer to this section how to turn cookies off if you so desire. </p>
          <h5>Aggregated Personal Data:</h5>
          <p>Spext often conducts research on its customer demographics, interests and behavior based on the Personal Data and other information provided to us to service users better. This research may be compiled and analyzed on an aggregate basis, and Spext may share this aggregate data with its affiliates and business partners. This aggregate information does not identify you personally. Spext may also disclose aggregated user statistics in order to describe our services to current and prospective business partners, and to other third parties for other lawful purposes.</p>
          <h5>Our Use of Your Personal Data and Other Information:</h5>
          <p>Spext uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. If you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services and to monitor your use of such services. spext and its subsidiaries and affiliates (the “Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the Services. Spext and its affiliates may use this information to contact you in the future to tell you about services that may be of interest to you. </p>
          <p>If we do so, each marketing communication we send you will contain instructions permitting you to "opt-out" of receiving future marketing communications. If at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.</p>
          <h5>Where we store your personal information and how we keep it safe:</h5>
          <p>The personal data that we collect from you will be transferred to and stored at a destination outside of the European Economic Area (“EEA”). We use best-in-class third party service providers to store data. </p>
          <p>Below are some of the methods we use to keep your data safe:</p>
          <h5>Data encryption:</h5>
          <p>We encrypt all data that goes between you and Spext using field-standard TLS (Transport Layer Security). Your data is encrypted when transferred between data centers for backup and replication.</p>
          <h5>Secure data centers:</h5>
          <p>Spext’s servers are located within enterprise-grade hosting facilities that have robust physical security controls to prevent physical access. These controls include 24/7/365 monitoring and surveillance, on-site security staff and regular ongoing security audits.
Security monitoring: Security teams continuously monitor security systems, event logs, notifications and alerts from all systems to identify and manage threats.</p>
          <h5>Our Disclosure of Your Personal Data and Other Information:</h5>
          <p>Spext is not in the business of selling your information. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:</p>
          <p>Business Transfers: In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.</p>
          <h5>Related Companies:</h5>
          <p>We may also share your Personal Data with our Related Companies for purposes consistent with this Privacy Policy.</p>
          <h5>Consultants and Related Third Parties:</h5>
          <p>Spext sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases and processing payments. If and when we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function.</p>
          <h5>Legal Requirements:</h5>
          <p>Spext may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Spext (iii)  in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) Protect against legal liability.</p>
          <h5>Your Choices:</h5>
          <p>You can visit the Site without providing any Personal Data. If you choose not to provide any Personal Data, you may not be able to use certain Services.</p>
          <h5>Exclusions:</h5>
          <p>This Privacy Policy does not apply to any Personal Data collected by Spext other than Personal Data collected through the Services. This Privacy Policy shall not apply to any unsolicited information you provide to spext through the Services or through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and Spext shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without attribution.</p>
          <h5>Children:</h5>
          <p>Spext does not knowingly collect Personal Data from children under the age of 13. If you are under the age of 13, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to spext through the Services, please contact us, and we will endeavor to delete that information from our databases.
Links to Other Web Sites:</p>
          <p>This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by Spext (the “Third Party Sites”). The policies and procedures we described here do not apply to the Third Party Sites. </p>
          <h5>Security:</h5>
          <p>Spext takes reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email.</p>
          <p></p>
          <h5>Other Terms and Conditions:</h5>
          <!-- <p>Your access to and use of the Services is subject to the Terms of Service at www.spext.co/tandc -->
<!-- Changes to Spext’s Privacy Policy:</p> -->
          <p>The Services and our business may change from time to time. As a result, at times it may be necessary for spext to make changes to this Privacy Policy. Spext reserves the right to update or modify this Privacy Policy at any time without prior notice. Please review this policy periodically, and especially before you provide any Personal Data. This Privacy Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
Access to Information; </p>
          <h5>Contacting Spext:</h5>
          <p>We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services. To delete or remove data, please contact us as specified below. You may contact us as follows: hello@spext.co</p>
        </div>
      </div>
    </div>

<div style="margin:10em;">
  &nbsp;
</div>

  </div>
</template>

<script>
require('@fortawesome/fontawesome-pro/css/all.css')
export default {
  components: {
    login: () => import('@/components/auth/Auth')
  },
  data () {
    return {
      menu: false
    }
  },
  mounted () {
    delete localStorage['retryCount']
  },
  methods: {
    showPricing () {
      // $('#pricing').modal('show')
      $.scrollTo(document.getElementById('pricing'), 800)
    },
    showBlog () {

    },
    showFAQ () {

    },
    showSecurity () {

    },
    showHome () {

    },
    showPrivacy () {

    },
    showGuide () {

    },
    showLegal () {

    },
    // closePricing () {
    //   $('#pricing').modal('hide')
    // },
    showLogin () {

    }
  }
}

</script>

<style lang="less" scoped>
 @media screen and (max-width: 1024px) {
    .hide {
        display: none !important;
    }
}
@media screen and (min-width: 0px) and (max-width: 1024px) {
    .show {
        display: flex;
    }
    .show1{
      display: block;
    }
    /* show it on small screens */
}
@media screen and (min-width: 1025px) and (max-width: 2048px) {
    .show {
        display: none;
    }
    .show1{
      display: none;
    }
    /* hide it elsewhere */
}
.round {
  border-radius: 6px !important;
}

.button {
  font-size: 13px !important;
  font-weight: bold;
}

.tealText {
  color: #5A29A3 !important;
}

.bottomMargin {
  margin-bottom: 0 !important;
}

.ui.menu .item:before {
  background: white;
}

.menuHeight {
  height: 5vh !important;
}

@media only screen and (max-width: 767px) {
  .menuHeight {
    height: initial !important;
  }
}

h1.ui.header {
  /* font-size: 35px; */
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #c7c7c7;
  margin-bottom: 5px;
  margin-top: 30px;
}

.menuItem{
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 15px;
  text-align: center;
  color: #515151;
  cursor: pointer;
}
h1.p1.header{

font-style: normal;
font-weight: bold;
line-height: normal;
font-size: 30px;
text-align: center;
text-transform: uppercase;

color: #C75146;
}
.p1.subheader{
font-style: normal;
font-weight: normal;
line-height: normal;
font-size: 18px;
text-align: center;

color: #515151;
}
.bolder{
  font-weight: 500;
}
h5{
  margin-bottom: 0;
font-style: normal;
font-weight: bold;
line-height: normal;
font-size: 20px;

color: #515151;
}
p{
font-style: normal;
font-weight: normal;
line-height: normal;
font-size: 20px;

color: #515151;
}
a.ui.item:hover{
  background: transparent;
}
</style>
